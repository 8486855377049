import { createRouter, createWebHistory } from "vue-router";
import VueRouterMetaTags from "@bachdgvn/vue-router-meta-tags";

const routes = [
  {
    path: "/",
    component: () => import("@/App.vue"),
    meta: {
      title: "Sodium - Hybrid Liquidity for NFT collectibles | SODIUM",
      metaTags: [
        {
          name: "description",
          content: "SODIUM - The first NFT Hybrid Liquidity Protocol. Effortlessly borrow, lend, and earn interest with digital assets."
        },
        {
          property: "og:description",
          content: "SODIUM - The first NFT Hybrid Liquidity Protocol. Effortlessly borrow, lend, and earn interest with digital assets."
         }
      ],
    },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(VueRouterMetaTags.update);

export default router;
