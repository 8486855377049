<template>
  <header>
    <div class="header" :class="{'sticky': windowTop > 740, 'opened': headerOpened}">
      <div class="container">
        <div class="header-in">
          <div class="logo-b">
            <img src="@/assets/img/logo.svg" alt="" class="logo">
          </div>
          <nav>
            <a @click="scrollTo('how-it-work')" class="link">How It Works</a>
            <a @click="scrollTo('blog')" class="link">Blog</a>
            <a @click="scrollTo('key-features')" class="link">Key Features</a>
            <a @click="scrollTo('faq')" class="link">FAQ</a>
          </nav>
          <a href="https://app.sodium.fi/" target="_blank" rel="noopener noreferrer" class="button">
            <span class="button_text">Launch App</span>
          </a>
          <button type="button" name="button" class="burger" @click="headerOpened = !headerOpened">
            <span class="burger-line"></span>
          </button>
        </div>
      </div>
    </div>
  </header>
  <main class="main">
    <section id="#mp-top" class="mp-top">
      <div class="container">
        <div class="mp_in">
          <div class="mp-top_left">
            <h1 class="title --lrg wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.25s">SODIUM</h1>
            <p class="mp-top_text wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.4s">Unlock the value of your NFT with next-gen <span class="t-accent">Hybrid Liquidity</span></p>
            <div class="mp-scroll wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.55s">
              <span class="mp-scroll_icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 20 30" fill="none">
                  <path d="M19 20C19 25 15 29 10 29C5.1 29 1 25 1 20V10C1 5.1 5.1 1 10 1C15 1 19 5.1 19 10V20Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                </svg>
                <span class="mp-scroll_line"></span>
              </span>
              <span class="mp-scroll_text">Scroll down</span>
            </div>
          </div>
          <div class="mp-cover-wrapper wow">
            <div class="mp-cover_light"></div>
            <div class="mp-cover wow fadeInRight" data-wow-duration="0.75s" data-wow-delay="0.55s">
              <div class="mp-cover_bg">
                <video autoplay playsinline loop muted preload="auto" style="object-fit: cover">
                  <source src="@/assets/video/cover.mp4" type="video/mp4">
                </video>
              </div>
              <div class="mp-cover_in in1">
                <span class="mp-cover_dot d1"></span>
              </div>
              <div class="mp-cover_in in2">
                <span class="mp-cover_dot d2"></span>
              </div>
              <div class="mp-cover_in in3">
                <span class="mp-cover_dot d3"></span>
              </div>
              <div class="mp-cover_info i1">
                <p class="mp-cover_info-text">Innovative Loan Fulfillment Process</p>
              </div>
              <div class="mp-cover_info i2">
                <p class="mp-cover_info-text">Highest Collateral Valuations Industry-wide</p>
              </div>
              <div class="mp-cover_info i3">
                <p class="mp-cover_info-text">Wide Number of Whitelisted Collections</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="#mp-nft" class="mp-nft">
      <div class="container">
        <div class="mp_in">
          <h2 class="title wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.25s">NFT Liquidity <br><span class="t-accent">Your</span> Way</h2>
          <p class="text wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.4s">Sodium allows Lenders to earn interest on provided liquidity, while helping Borrowers unlock the value of their NFT. Unique, automated lending functionality allows NFT creators to create lending markets for their projects and communities.</p>
        </div>
      </div>
    </section>
    <section id="#mp-first" class="mp-first">
      <div class="container">
        <div class="mp-first_in">
          <div class="mp-first_block">
            <div class="mp-first_video-wrapper">
              <div class="mp-first_video">
                <video autoplay playsinline loop muted preload="auto" style="object-fit: cover">
                  <source src="@/assets/video/wtf.mp4" type="video/mp4">
                </video>
              </div>
            </div>
            <h2 class="title --md wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.25s">More <span class="t-accent">Liquidity</span> <br>Fewer Limitations</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="#how-it-work" class="mp-hit" ref="how-it-work">
      <div class="container">
        <div class="mp-hit_in">
          <h2 class="title wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.25s">How It <span class="t-accent">Works</span></h2>
          <div class="mp-hit_left">
            <p class="text wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.35s">Sodium connects NFT holders to the Hybrid Liquidity market.</p>
            <p class="text wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.45s">By combining P2P flexibility with P2Pool efficiency, Sodium enables rapid loan fulfillment and high collateral valuation for a wide number of whitelisted collections.</p>
            <p class="text wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.55s">Lenders provide liquidity according to their own risk-reward tolerance, while Borrowers can choose the loan offers that best suit their needs.</p>
            <a href="https://docs.sodium.fi/" target="_blank" rel="noopener noreferrer" class="link-to wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.75s">
              <span class="link-to_text">Full Documentation</span>
              <svg class="link-to_icon" width="12" height="13" viewBox="0 0 12 13" fill="none">
                <path d="M4.4751 2.53996L7.7351 5.79996C8.1201 6.18496 8.1201 6.81496 7.7351 7.19996L4.4751 10.46" stroke="#FDC54B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </a>
          </div>
          <div class="mp-item-wrapper">
            <div class="mp-item_light-bg"></div>
            <div class="mp-item_light"></div>
            <div class="mp-item_light-accent"></div>
            <div class="mp-item-b wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.45s">
              <div class="mp-item-list">
                <div class="mp-item center">
                  <div class="mp-item_in">
                    <div class="mp-item_icon --center"></div>
                    <span class="mp-item_title">Sodium</span>
                  </div>
                  <div class="mp-item_bg"></div>
                </div>
                <div class="mp-item i1">
                  <div class="mp-item_in">
                    <div class="mp-item_icon --red">
                      <img src="@/assets/img/icon-red.svg" alt="">
                    </div>
                    <span class="mp-item_title">Pool Liquidity</span>
                  </div>
                  <span class="mp-item_line"></span>
                  <div class="mp-item_bg"></div>
                </div>
                <div class="mp-item i2">
                  <div class="mp-item_in">
                    <div class="mp-item_icon --green">
                      <img src="@/assets/img/icon-green.svg" alt="">
                    </div>
                    <span class="mp-item_title">Borrower</span>
                  </div>
                  <span class="mp-item_line"></span>
                  <div class="mp-item_bg"></div>
                </div>
                <div class="mp-item i3">
                  <div class="mp-item_in">
                    <div class="mp-item_icon --accent">
                      <img src="@/assets/img/icon-accent.svg" alt="">
                    </div>
                    <span class="mp-item_title">Peer Liquidity</span>
                  </div>
                  <span class="mp-item_line"></span>
                  <div class="mp-item_bg"></div>
                </div>
              </div>
              <img src="@/assets/img/item-rotate.gif" alt="" class="mp-item_gif">
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="#mp-version" class="mp-version">
      <div class="container">
        <div class="mp-version_in">
          <h2 class="title wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.25s">An <span class="t-accent">Unbeatable</span> <br>NFT liquidity experience</h2>
          <div class="mp-version_content wow">
            <div class="mp-version_line-b">
              <div class="mp-version_line-item">
                <div class="mp-version_line-left">
                  <div class="mp-card-wrapper wow" :class="{'show':blockCard}">
                    <div class="mp-card-b">
                      <div class="mp-card c1">
                        <div class="mp-card_in">
                          <div class="mp-card_img">
                            <img src="@/assets/img/wide1.png" alt="">
                          </div>
                          <div class="mp-card_content">
                            <span class="mp-card_collection --check">Grails II Mint Pass</span>
                            <span class="mp-card_title">Grails II Mint Pass</span>
                          </div>
                        </div>
                      </div>
                      <div class="mp-card c2">
                        <div class="mp-card_in">
                          <div class="mp-card_img">
                            <img src="@/assets/img/wide2.png" alt="">
                          </div>
                          <div class="mp-card_content">
                            <span class="mp-card_collection --check">The 187 by Truth Labs</span>
                            <span class="mp-card_title">Parika of Mirachinest</span>
                          </div>
                        </div>
                      </div>
                      <div class="mp-card c3">
                        <div class="mp-card_in">
                          <div class="mp-card_img">
                            <img src="@/assets/img/wide-rotate.gif" alt="">
                          </div>
                          <div class="mp-card_content">
                            <span class="mp-card_collection --check">The 187 by Truth Labs</span>
                            <span class="mp-card_title">Paths #336</span>
                          </div>
                        </div>
                      </div>
                      <div class="mp-card c4">
                        <div class="mp-card_in">
                          <div class="mp-card_img">
                            <img src="@/assets/img/wide4.png" alt="">
                          </div>
                          <div class="mp-card_content">
                            <span class="mp-card_collection --check">Abstract Energy</span>
                            <span class="mp-card_title">Abstract Energy #6</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mp-version_line wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.35s" @mouseover="showBlock('card')" :class="{'active': blockCard}">
                  <h4 class="mp-version_line-title">Wide Number of Whitelisted Collections</h4>
                  <p class="mp-version_line-text">Sodium is collection agnostic. Whitelisting is only necessary to protect users against NFT-related scams </p>
                </div>
              </div>
              <div class="mp-version_line-item">
                <div class="mp-version_line-left">
                  <div class="mp-version_rotate-wrapper" :class="{'show': blockRotate}">
                    <video autoplay playsinline loop muted preload="auto" style="object-fit: cover">
                      <source src="@/assets/video/rotate.mp4" type="video/mp4">
                    </video>
                  </div>
                </div>
                <div class="mp-version_line wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.45s" @mouseover="showBlock('rotate')" :class="{'active': blockRotate}">
                  <h4 class="mp-version_line-title">Highest Collateral Valuation Industry-wide</h4>
                  <p class="mp-version_line-text">With Sodium’s efficient collateral valuation system, certain tokens can even get more than 100% of the floor price</p>
                </div>
              </div>
              <div class="mp-version_line-item">
                <div class="mp-version_line-left">
                  <div class="mp-version_video-wrapper" :class="{'show': blockVideo}">
                    <div class="mp-version_video">
                      <video autoplay playsinline loop muted preload="auto" style="object-fit: cover">
                        <source src="@/assets/video/advantage.mp4" type="video/mp4">
                      </video>
                    </div>
                  </div>
                </div>
                <div class="mp-version_line wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.55s" @mouseover="showBlock('video')" :class="{'active': blockVideo}">
                  <h4 class="mp-version_line-title">Innovative Loan Fulfillment Process</h4>
                  <p class="mp-version_line-text">Our unique price discovery mechanics create competition on the lending market, ensuring rapid Loan Request fulfillment</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="#mp-use" class="mp-use" ref="key-features">
      <div class="container">
        <div class="mp-use_in">
          <h2 class="title wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.25s">Using SODIUM</h2>
          <div class="legend-b wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.35s">
            <span class="legend --accent" @click="activeTab = 1" :class="{'active': activeTab == 1}">Lending</span>
            <span class="legend --yellow" @click="activeTab = 2" :class="{'active': activeTab == 2}">Borrowing</span>
          </div>
          <div class="step-b wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.5s">
            <div class="step_left wow" :class="{'active': activeTab == 1}">
              <div class="step_item">
                <svg width="304" height="617" viewBox="0 0 304 617" fill="none">
                  <mask id="path-1-inside-1_393_3959" fill="white">
                  <path d="M303.5 617C263.644 617 224.178 609.02 187.356 593.517C150.533 578.013 117.076 555.289 88.8931 526.642C60.7105 497.996 38.3549 463.987 23.1025 426.558C7.85028 389.129 0 349.013 0 308.5C0 267.987 7.85028 227.871 23.1025 190.442C38.3549 153.013 60.7105 119.004 88.8931 90.3575C117.076 61.7107 150.533 38.9867 187.356 23.4832C224.178 7.97958 263.644 -3.54174e-06 303.5 0V1.54251C263.843 1.54251 224.575 9.48219 187.936 24.9082C151.298 40.3343 118.008 62.9446 89.9661 91.4483C61.9244 119.952 39.6806 153.791 24.5045 191.032C9.32849 228.274 1.51752 268.19 1.51752 308.5C1.51746 348.81 9.32849 388.726 24.5045 425.968C39.6806 463.209 61.9244 497.048 89.9661 525.552C118.008 554.055 151.298 576.666 187.936 592.092C224.574 607.518 263.843 615.458 303.5 615.458V617Z"/>
                  </mask>
                  <path d="M303.5 617C263.644 617 224.178 609.02 187.356 593.517C150.533 578.013 117.076 555.289 88.8931 526.642C60.7105 497.996 38.3549 463.987 23.1025 426.558C7.85028 389.129 0 349.013 0 308.5C0 267.987 7.85028 227.871 23.1025 190.442C38.3549 153.013 60.7105 119.004 88.8931 90.3575C117.076 61.7107 150.533 38.9867 187.356 23.4832C224.178 7.97958 263.644 -3.54174e-06 303.5 0V1.54251C263.843 1.54251 224.575 9.48219 187.936 24.9082C151.298 40.3343 118.008 62.9446 89.9661 91.4483C61.9244 119.952 39.6806 153.791 24.5045 191.032C9.32849 228.274 1.51752 268.19 1.51752 308.5C1.51746 348.81 9.32849 388.726 24.5045 425.968C39.6806 463.209 61.9244 497.048 89.9661 525.552C118.008 554.055 151.298 576.666 187.936 592.092C224.574 607.518 263.843 615.458 303.5 615.458V617Z" stroke="#828282" stroke-width="2" stroke-dasharray="5 5" mask="url(#path-1-inside-1_393_3959)"/>
                </svg>
              </div>
              <span class="step --left s1 wow fadeIn" data-wow-duration="0.3s" data-wow-delay="0.75s">01</span>
              <span class="step --left s2 wow fadeIn" data-wow-duration="0.3s" data-wow-delay="1.25s">02</span>
              <span class="step --left s3 wow fadeIn" data-wow-duration="0.3s" data-wow-delay="1.75s">03</span>
              <span class="step --left s4 wow fadeIn" data-wow-duration="0.3s" data-wow-delay="2.25s">04</span>
              <div class="step-info_list">
                <div class="step-info --left s1">
                  <h4 class="step-info_title">
                    <span class="t-accent">01</span>
                    <span>Automated Lending</span>
                  </h4>
                  <p class="step-info_text">Stake funds into any existing Lending Pool, that satisfies your risk-reward expectations</p>
                </div>
                <div class="step-info --left s2">
                  <h4 class="step-info_title">
                    <span class="t-accent">02</span>
                    <span>Flexible Lending Terms</span>
                  </h4>
                  <p class="step-info_text">Define personal risk-reward strategies based on a collateral’s current valuation</p>
                </div>
                <div class="step-info --left s3">
                  <h4 class="step-info_title">
                    <span class="t-accent">03</span>
                    <span>Active Funds Control</span>
                  </h4>
                  <p class="step-info_text">Leverage your NFT expertise and maximize gains by being active in the Peer market</p>
                </div>
                <div class="step-info --left s4">
                  <h4 class="step-info_title">
                    <span class="t-accent">04</span>
                    <span>Custom Liquidity Market</span>
                  </h4>
                  <p class="step-info_text">Initiate, manage and earn via dedicated Liquidity Pools for specific collections</p>
                </div>
              </div>
            </div>
            <div class="step_sun wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.35s">
              <div class="step_sun-video">
                <video autoplay playsinline loop muted preload="auto" style="object-fit: cover">
                  <source src="@/assets/video/sun.mp4" type="video/mp4">
                </video>
              </div>
            </div>
            <div class="step_right wow" :class="{'active': activeTab == 2}">
              <div class="step_item">
                <svg width="304" height="617" viewBox="0 0 304 617" fill="none">
                  <mask id="path-1-inside-1_551_3486" fill="white">
                  <path d="M0.5 617C40.3562 617 79.8221 609.02 116.644 593.517C153.467 578.013 186.924 555.289 215.107 526.642C243.289 497.996 265.645 463.987 280.897 426.558C296.15 389.129 304 349.013 304 308.5C304 267.987 296.15 227.871 280.897 190.442C265.645 153.013 243.289 119.004 215.107 90.3575C186.924 61.7107 153.467 38.9867 116.644 23.4832C79.8221 7.97958 40.3562 -3.54174e-06 0.499969 0V1.54251C40.1569 1.54251 79.4255 9.48219 116.064 24.9082C152.702 40.3343 185.992 62.9446 214.034 91.4483C242.076 119.952 264.319 153.791 279.495 191.032C294.672 228.274 302.482 268.19 302.482 308.5C302.483 348.81 294.672 388.726 279.495 425.968C264.319 463.209 242.076 497.048 214.034 525.552C185.992 554.055 152.702 576.666 116.064 592.092C79.4255 607.518 40.1569 615.458 0.5 615.458V617Z"/>
                  </mask>
                  <path d="M0.5 617C40.3562 617 79.8221 609.02 116.644 593.517C153.467 578.013 186.924 555.289 215.107 526.642C243.289 497.996 265.645 463.987 280.897 426.558C296.15 389.129 304 349.013 304 308.5C304 267.987 296.15 227.871 280.897 190.442C265.645 153.013 243.289 119.004 215.107 90.3575C186.924 61.7107 153.467 38.9867 116.644 23.4832C79.8221 7.97958 40.3562 -3.54174e-06 0.499969 0V1.54251C40.1569 1.54251 79.4255 9.48219 116.064 24.9082C152.702 40.3343 185.992 62.9446 214.034 91.4483C242.076 119.952 264.319 153.791 279.495 191.032C294.672 228.274 302.482 268.19 302.482 308.5C302.483 348.81 294.672 388.726 279.495 425.968C264.319 463.209 242.076 497.048 214.034 525.552C185.992 554.055 152.702 576.666 116.064 592.092C79.4255 607.518 40.1569 615.458 0.5 615.458V617Z" stroke="#828282" stroke-width="2" stroke-dasharray="5 5" mask="url(#path-1-inside-1_551_3486)"/>
                </svg>
              </div>
              <span class="step --right s1 wow fadeIn" data-wow-duration="0.3s" data-wow-delay="0.75s">01</span>
              <span class="step --right s2 wow fadeIn" data-wow-duration="0.3s" data-wow-delay="1.25s">02</span>
              <span class="step --right s3 wow fadeIn" data-wow-duration="0.3s" data-wow-delay="1.75s">03</span>
              <span class="step --right s4 wow fadeIn" data-wow-duration="0.3s" data-wow-delay="2.25s">04</span>
              <div class="step-info_list">
                <div class="step-info --right s1">
                  <h4 class="step-info_title">
                    <span class="t-yellow">01</span>
                    <span>Instant Borrowing</span>
                  </h4>
                  <p class="step-info_text">Receive instant Pool liquidity for select collections</p>
                </div>
                <div class="step-info --right s2">
                  <h4 class="step-info_title">
                    <span class="t-yellow">02</span>
                    <span>High LTV Ratio</span>
                  </h4>
                  <p class="step-info_text">Competition in the lending market means higher borrowing limits for Borrowers</p>
                </div>
                <div class="step-info --right s3">
                  <h4 class="step-info_title">
                    <span class="t-yellow">03</span>
                    <span>Asset Protection</span>
                  </h4>
                  <p class="step-info_text">Pleged collateral is guaranteed against liquidation throughout the entire loan term</p>
                </div>
                <div class="step-info --right s4">
                  <h4 class="step-info_title">
                    <span class="t-yellow">04</span>
                    <span>Retain Your NFT’s Utility</span>
                  </h4>
                  <p class="step-info_text">Enjoy your NFT's exclusive utilities during the loan term, e.g. connecting to Twitter PFP</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="#mp-start" class="mp-start">
      <div class="container">
        <div class="mp-start_in">
          <div class="mp-start_text-b">
            <h2 class="title wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.25s">Get <span class="t-accent">Started</span> Today</h2>
            <p class="mp-start_text wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.35s">Lend, Borrow and create markets, effortlessly.</p>
            <a href="https://app.sodium.fi/" target="_blank" rel="noopener noreferrer" class="button wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.45s">
              <span class="button_text">Launch App</span>
            </a>
          </div>
          <img src="@/assets/img/gst-top.png" alt="" class="mp-start_img i1 wow fadeInUp" data-wow-duration="0.3s" data-wow-delay="0.25s">
          <img src="@/assets/img/gst-right.png" alt="" class="mp-start_img i2 wow fadeInRight" data-wow-duration="0.3s" data-wow-delay="0.45s">
          <img src="@/assets/img/gst-left.png" alt="" class="mp-start_img i3 wow fadeInLeft" data-wow-duration="0.3s" data-wow-delay="0.45s">
        </div>
      </div>
    </section>
    <section id="#mp-assets" class="mp-assets">
      <div class="container">
        <div class="mp_in">
          <div class="mp-assets_left">
            <h2 class="title wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.25s">Your Assets <br>Are <span class="t-accent">Secure</span></h2>
            <img src="@/assets/img/shield.png" class="mp-assets_img wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.45s">
            <p class="text wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.65s">Sodium has been audited by PeckShield. We don't take any chances when it comes to protocol security.</p>
            <a href="https://github.com/sodium-fi/sodium-protocol/blob/main/PeckShield-Audit-Report-Sodium-v1.0.pdf" target="_blank" rel="noopener noreferrer" class="button --secondary wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.85s">
              <span class="button_text">Access Security Report</span>
            </a>
          </div>
          <img src="@/assets/img/shield.png" class="mp-assets_img wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.65s">
        </div>
      </div>
    </section>
    <section id="#mp-faq" class="mp-faq" ref="faq">
      <div class="mp-faq_light"></div>
      <div class="container">
        <div class="mp-faq_in">
          <h2 class="title wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.25s">FAQ</h2>
          <ul class="mp-faq_list">
            <li class="mp-faq_list-item wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.35s">
              <article class="mp-faq_item" :class="{'--opened': question1}">
                <h4 class="mp-faq_item-title" @click="question1 = !question1">What options do Borrowers have when using SODIUM?</h4>
                <p class="mp-faq_item-text">"SODIUM's Hybrid Liquidity design allows borrowers to take out a loan in two ways:</p>
                <p class="mp-faq_item-text">1. Peer-to-Peer Loan</p>
                <p class="mp-faq_item-text">Any NFT from a whitelisted collection can be used to obtain a loan from the P2P market - just set the initial APR, specify the duration of the loan, and publish the Loan request; the request will remain open for the next 48 hours. During this time, Borrowers can partially or fully accept offers from P2P lenders if the APR meets their expectations.</p>
                <p class="mp-faq_item-text">2. Instant Loan + (optional) Peer-to-Peer Loan</p>
                <p class="mp-faq_item-text">Using NFT collateral that qualifies for an Instant Loan, you'll be able to receive part of the funds you need immediately. In addition, a higher LTV ratio can be achieved waiting for P2P Lenders to fulfill on top of the Instant Loan.</p>
              </article>
            </li>
            <li class="mp-faq_list-item wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.45s">
              <article class="mp-faq_item" :class="{'--opened': question2}">
                <h4 class="mp-faq_item-title" @click="question2 = !question2">How can Lenders participate on SODIUM?</h4>
                <p class="mp-faq_item-text">Lenders can participate in any Loan Request listed on the Lending page. The specific APR earned by a Lender depends on when they participated in the open Loan Request.</p>
                <p class="mp-faq_item-text">All loan requests are open for 48h max; the initial APR set by the Borrower increases incrementally over time.</p>
                <p class="mp-faq_item-text">Each Lender's loan contribution is placed on top of previous offers, increasing the collateral valuation by the amount of the contribution. Their place in this order represents the risk they take when making a loan; the higher collateral valuation, the more risk a Lender bears. </p>
                <p class="mp-faq_item-text">In addition, Lenders have the option to add their funds to a Lending Pool that meets their risk/reward expectations. Note, Lending Pools will be launched very soon, specifics TBA on <a href="https://twitter.com/Sodium_fi" target="_blank" rel="noopener noreferrer">Twitter</a>.</p>
              </article>
            </li>
            <li class="mp-faq_list-item wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.55s">
              <article class="mp-faq_item" :class="{'--opened': question3}">
                <h4 class="mp-faq_item-title" @click="question3 = !question3">How does the repayment process work?</h4>
                <p class="mp-faq_item-text">The Borrower shall partially repay or repay the loan in full before the end of the agreed-upon period. When making principal repayments before the end of the loan term, the Borrower must also pay at least half of the corresponding interest due. Partial repayments are distributed to Lenders starting from the top of the Lending Queue, resulting in decreasing average APR for the Borrower. Once the loan is fully repaid, the collateral can be reclaimed.</p>
                <p class="mp-faq_item-text">Note, if the loan is not fully repaid by the end of the loan term, liquidation will occur.</p>
              </article>
            </li>
            <li class="mp-faq_list-item wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.65s">
              <article class="mp-faq_item" :class="{'--opened': question4}">
                <h4 class="mp-faq_item-title" @click="question4 = !question4">How does the liquidation process work?</h4>
                <p class="mp-faq_item-text">The Liquidation Auction begins if the loan is not fully repaid by the end of the loan term.</p>
                <p class="mp-faq_item-text">Participants can either pay the ""Buy It Now"" price to immediately win the Auction, or participate by placing a bid. The Auction ends after 24 hours has passed, at which time the highest bid will be accepted. If no bid was placed during the 24-hour Liquidation Auction period, the collateral will be transferred to the first Lender in the Lending Queue.</p>
                <p class="mp-faq_item-text">During the Liquidation process, Lenders who participated at lower collateral valuations will be repaid first.</p>
              </article>
            </li>
            <li class="mp-faq_list-item wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.75s">
              <article class="mp-faq_item" :class="{'--opened': question5}">
                <h4 class="mp-faq_item-title" @click="question5 = !question5">Will my NFT retain its utility while it's collateralized?</h4>
                <p class="mp-faq_item-text">Yes! An NFT's utility can be retained by using the Sodium Smart Wallet when requesting a loan. Utilizing the Smart Wallet, you'll have unmatched flexbility during the loan term - you can claim Airdrops, change your Twitter PFP, nest your Moonbirds, or connect to Collab.Land (or any other dApp). Of course, selling or trading the collateral during the loan term would invalidate the loan, hence these are the only functions not allowed by the Smart Wallet.</p>
                <p class="mp-faq_item-text">Note, first-time users will need to spend a little extra gas to create a Sodium Smart Wallet.</p>
              </article>
            </li>
            <li class="mp-faq_list-item wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.85s">
              <article class="mp-faq_item" :class="{'--opened': question6}">
                <h4 class="mp-faq_item-title" @click="question6 = !question6">What NFTs can be used to obtain a Loan?</h4>
                <p class="mp-faq_item-text">Sodium accepts whitelisted NFTs from both ERC-721 and ERC-1155 standards as collateral.</p>
                <p class="mp-faq_item-text">The goal of having a whitelist is to avoid exposing our users to spam collections, while also ensuring the general security and efficiency of the platform. Collections are whitelisted after analyzing certain criteria, including Market Cap, Trading Volume, Floor Price, etc.</p>
                <p class="mp-faq_item-text">If you think that your collection should be whitelisted simply reach out to us via <a href="https://twitter.com/Sodium_fi" target="_blank" rel="noopener noreferrer">Twitter</a> or <a href="https://discord.gg/gpABvrXr83" target="_blank" rel="noopener noreferrer">Discord</a>.</p>
              </article>
            </li>
          </ul>
          <div class="mp-faq_button wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.95s">
            <a href="https://docs.sodium.fi/" target="_blank" rel="noopener noreferrer" class="button --secondary">
              <span class="button_text">Learn More</span>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section id="#blog" class="mp-blog" ref="blog">
      <div class="container">
        <div class="mp-blog_in">
          <div class="title-b wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.25s">
            <h2 class="title"><span class="t-accent">Explore</span> more</h2>
            <a href="https://sodiumofficial.medium.com/" target="_blank" rel="noopener noreferrer" class="link-to wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.75s">
              <span class="link-to_text">See All</span>
              <svg class="link-to_icon" width="12" height="13" viewBox="0 0 12 13" fill="none">
                <path d="M4.4751 2.53996L7.7351 5.79996C8.1201 6.18496 8.1201 6.81496 7.7351 7.19996L4.4751 10.46" stroke="#FDC54B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </a>
          </div>
          <ul class="mp-blog_list wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.4s">
            <template v-for="(item,index) in data.items" :key="index">
              <template v-if="index < 3">
                <li class="mp-blog_list-item">
                  <article class="mp-blog_item">
                    <div class="mp-blog_item-content">
                      <div class="mp-blog_item-img">
                        <img :src="item.thumbnail" alt="">
                      </div>
                      <h4 class="mp-blog_item-title">{{item.title}}</h4>
                      <div class="mp-blog_item-text" v-html="item.description"></div>
                    </div>
                    <a :href="item.link" class="link-to">
                      <span class="link-to_text">Read more</span>
                      <svg class="link-to_icon" width="12" height="13" viewBox="0 0 12 13" fill="none">
                        <path d="M4.4751 2.53996L7.7351 5.79996C8.1201 6.18496 8.1201 6.81496 7.7351 7.19996L4.4751 10.46" stroke="#FDC54B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </a>
                  </article>
                </li>
              </template>
            </template>
          </ul>
        </div>
      </div>
    </section>
    <div class="modal-wrapper" :class="{'show': showModal}" @click="showModal = false">
      <div class="modal" :class="{'show': showModal}" @click.stop="">
        <h4 class="modal_title">Launching Soon!</h4>
        <p class="modal_text">Gm, degen! Sodium will be live in a matter of weeks! In the meantime, please join our community via the links below:</p>
        <div class="modal-button-b">
          <a href="https://discord.gg/gpABvrXr83" target="_blank" rel="noopener noreferrer" class="modal-button">
            <svg width="32" height="24" viewBox="0 0 32 24" fill="none" class="modal-button_icon">
              <path d="M27.1071 2.00996C25.0047 1.05745 22.7851 0.381713 20.5052 0C20.1933 0.551901 19.911 1.11973 19.6596 1.70112C17.231 1.33898 14.7614 1.33898 12.3328 1.70112C12.0813 1.11979 11.799 0.551968 11.4872 0C9.20579 0.384937 6.98482 1.06228 4.88027 2.01494C0.702194 8.132 -0.430416 14.0971 0.135889 19.9776C2.58267 21.7665 5.32132 23.127 8.23279 24C8.88837 23.1275 9.46847 22.2018 9.96694 21.2329C9.02016 20.883 8.10635 20.4512 7.23609 19.9427C7.46513 19.7783 7.68914 19.609 7.90559 19.4446C10.4378 20.623 13.2017 21.234 16 21.234C18.7983 21.234 21.5621 20.623 24.0944 19.4446C24.3133 19.6214 24.5373 19.7908 24.7639 19.9427C23.8919 20.4521 22.9764 20.8846 22.028 21.2354C22.5258 22.2039 23.106 23.1287 23.7621 24C26.6761 23.1305 29.4168 21.7707 31.8641 19.9801C32.5285 13.1606 30.7289 7.25031 27.1071 2.00996ZM10.6843 16.3611C9.10616 16.3611 7.8024 14.944 7.8024 13.2005C7.8024 11.457 9.06085 10.0274 10.6792 10.0274C12.2976 10.0274 13.5913 11.457 13.5636 13.2005C13.5359 14.944 12.2926 16.3611 10.6843 16.3611ZM21.3157 16.3611C19.7351 16.3611 18.4363 14.944 18.4363 13.2005C18.4363 11.457 19.6948 10.0274 21.3157 10.0274C22.9366 10.0274 24.2202 11.457 24.1925 13.2005C24.1648 14.944 22.924 16.3611 21.3157 16.3611Z" fill="#B0A6EE"/>
            </svg>
            <span class="modal-button_text">Discord</span>
          </a>
          <a href="https://twitter.com/Sodium_fi" target="_blank" rel="noopener noreferrer" class="modal-button">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="modal-button_icon">
              <path d="M23.643 4.93702C22.808 5.30702 21.911 5.55702 20.968 5.67002C21.941 5.08781 22.669 4.17148 23.016 3.09202C22.1019 3.63501 21.1014 4.01721 20.058 4.22202C19.3564 3.47288 18.4271 2.97634 17.4143 2.80949C16.4016 2.64264 15.3621 2.81481 14.4572 3.29927C13.5524 3.78373 12.8328 4.55338 12.4102 5.48872C11.9875 6.42406 11.8855 7.47277 12.12 8.47202C10.2677 8.37901 8.45564 7.89757 6.80144 7.05892C5.14723 6.22028 3.68785 5.04318 2.51801 3.60402C2.11801 4.29402 1.88801 5.09402 1.88801 5.94602C1.88757 6.71301 2.07644 7.46826 2.43789 8.14475C2.79934 8.82124 3.32217 9.39805 3.96001 9.82402C3.22029 9.80048 2.49688 9.6006 1.85001 9.24102V9.30102C1.84994 10.3768 2.22204 11.4194 2.90319 12.252C3.58434 13.0846 4.53258 13.6559 5.58701 13.869C4.9008 14.0547 4.18135 14.0821 3.48301 13.949C3.78051 14.8746 4.36001 15.684 5.14038 16.2639C5.92075 16.8438 6.86293 17.1652 7.83501 17.183C6.18484 18.4784 4.1469 19.1811 2.04901 19.178C1.67739 19.1781 1.30609 19.1564 0.937012 19.113C3.06649 20.4822 5.54535 21.2088 8.07701 21.206C16.647 21.206 21.332 14.108 21.332 7.95202C21.332 7.75202 21.327 7.55002 21.318 7.35002C22.2293 6.69099 23.0159 5.87491 23.641 4.94002L23.643 4.93702Z" fill="#56CCF2"/>
            </svg>
            <span class="modal-button_text">Twitter</span>
          </a>
          <a href="https://sodiumofficial.medium.com/" target="_blank" rel="noopener noreferrer" class="modal-button">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="modal-button_icon">
              <g clip-path="url(#clip0_815_3767)">
                <path d="M13.5375 12C13.5375 15.7275 10.5075 18.75 6.768 18.75C5.8804 18.7512 5.00125 18.5775 4.18076 18.239C3.36026 17.9004 2.61449 17.4035 1.98603 16.7767C1.35756 16.1499 0.858704 15.4055 0.517941 14.5859C0.177178 13.7663 0.00118111 12.8876 0 12C0 8.27101 3.03 5.25001 6.768 5.25001C7.65573 5.24863 8.53504 5.42214 9.3557 5.76063C10.1764 6.09912 10.9223 6.59596 11.5509 7.22278C12.1796 7.84959 12.6786 8.5941 13.0194 9.41379C13.3603 10.2335 13.5363 11.1123 13.5375 12ZM20.9625 12C20.9625 15.51 19.4475 18.354 17.5785 18.354C15.7095 18.354 14.1945 15.5085 14.1945 12C14.1945 8.49001 15.7095 5.64601 17.5785 5.64601C19.4475 5.64601 20.9625 8.49151 20.9625 12ZM24 12C24 15.144 23.4675 17.6925 22.809 17.6925C22.152 17.6925 21.6195 15.1425 21.6195 12C21.6195 8.85601 22.152 6.30751 22.8105 6.30751C23.4675 6.30751 24 8.85601 24 12Z" fill="white"/>
              </g>
              <defs>
              <clipPath id="clip0_815_3767">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
              </defs>
            </svg>
            <span class="modal-button_text">Medium</span>
          </a>
        </div>
      </div>
    </div>
  </main>
  <footer>
    <div class="container">
      <div class="footer">
        <div class="footer_in">
          <div class="footer_item --logo wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.25s">
            <img src="@/assets/img/logo.svg" alt="" class="logo">
            <p class="footer_text">SODIUM - A hybrid liquidity platform for borrowing against NFT collateral</p>
          </div>
          <div class="footer_item-b">
            <div class="footer_item wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.35s">
              <h4 class="footer_title">Links</h4>
              <a @click="scrollTo('how-it-work')" class="link">How It Works</a>
              <a @click="scrollTo('blog')" class="link">Blog</a>
              <a @click="scrollTo('key-features')" class="link">Key Features</a>
              <a @click="scrollTo('faq')" class="link">FAQ</a>
            </div>
            <div class="footer_item wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.45s">
              <h4 class="footer_title">Contact Us</h4>
              <a href="https://discord.gg/gpABvrXr83" target="_blank" class="link">Discord</a>
              <a href="https://twitter.com/Sodium_fi" target="_blank" class="link">Twitter</a>
              <a href="https://t.me/sodiumannouncements" class="link">Telegram</a>
            </div>
          </div>
          <div class="footer_item --button wow fadeInUp" data-wow-duration="0.75s" data-wow-delay="0.55s">
            <a href="https://app.sodium.fi/" target="_blank" rel="noopener noreferrer" class="button">
              <span class="button_text">Launch App</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  import axios from 'axios'
  import WOW from 'wowjs'
  import 'wowjs/css/libs/animate.css'

  export default {
    data() {
      return {
        showModal: false,
        headerOpened: false,
        activeTab: 1,
        blockCard: true,
        blockVideo: false,
        blockRotate: false,
        question1: false,
        question2: false,
        question3: false,
        question4: false,
        question5: false,
        question6: false,
        windowTop: 0,
        baseURL: 'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@sodiumofficial',
        data: ''
      };
    },
    methods: {
      onScroll() {
        this.windowTop = window.top.scrollY;
        this.headerOpened = false
      },
      showBlock(type) {
        this.blockCard   = (type == 'card') ? true : false;
        this.blockRotate = (type == 'rotate') ? true : false;
        this.blockVideo  = (type == 'video') ? true : false
      },
      scrollTo(refName) {
        this.$refs[refName].scrollIntoView({ behavior: "smooth" })
      }
    },
    mounted() {
      window.addEventListener("scroll", this.onScroll);

      let wow = new WOW.WOW({
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 0,
        mobile: true,
        live: false,
        resetAnimation: false
      });
      wow.init();

      axios.get(this.baseURL)
         .then((response) =>{
           this.data = response.data
         })
         .catch(error => console.log(error))
    },
    beforeUnmount() {
      window.removeEventListener("scroll", this.onScroll)
    },
  }
</script>

<style lang="scss">
  @import "@/assets/landing.scss";
</style>
